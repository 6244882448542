import React from 'react'
import { motion } from 'framer-motion'
import styles from './stylesheets/Footer.module.css'

const Footer = () => {

  return (
    <footer className={styles.footer}>
      <div className={`container`}>
        <div className={`row py-2`}>
          <p style={{ textAlign: `center`, width: `100%` }}>Made with <motion.div animate={{ scale: [0.9, 1.1, 0.9]}} transition={{ repeat: Infinity }} style={{ display: `inline-block`}}>❤️</motion.div> by Bart Jan, Dennis, and Dennis</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
