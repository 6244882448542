import React from 'react'
// import { motion } from 'framer-motion'
import './stylesheets/Layout.css'
import './stylesheets/reset.css'
import './stylesheets/grid.css'

import Footer from '../components/Footer'

const Layout = ({ children }) => {

  return (
    <main>
     {/* <h1>Header</h1> */}
      <div id={'main-content'}>
        {children}
      </div>
      <Footer />
    </main>
  )
}

export default Layout
